import { ProductOffer, ProductOfferType } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { PERCENT_SERVICE_CALENDLY_URL_TILES } from './appStoreUrls'
import { ProgramName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

const productOffersCatalog = {
  adobe: {
    type: ProductOfferType.PartnerProduct,
    name: 'Adobe Acrobat Pro',
    tracking: ProgramName.Adobe,
    description: 'Save up to 94% on Adobe Acrobat Pro to create, edit, and share your mission’s stories',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Adobe.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.adobe.com/nonprofits/acrobat.html',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  airslate: {
    type: ProductOfferType.PartnerProduct,
    name: 'Airslate',
    tracking: ProgramName.Airslate,
    description:
      'Simplify workflows with 40% off Airslate’s platform for document generation, e-signatures, and automation',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Airslate.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cks5-zxf-czc',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  asana: {
    type: ProductOfferType.PartnerProduct,
    name: 'Asana',
    tracking: ProgramName.Asana,
    description: 'Boost team productivity with 50% off Asana’s work management tools and access dedicated support',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Asana.png'),
    primaryButton: {
      linkType: 'typeform',
      link: 'ttps://form.typeform.com/to/Va07vpDP',
      typeformId: 'Va07vpDP',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  atlassian: {
    type: ProductOfferType.PartnerProduct,
    name: 'Atlassian',
    tracking: ProgramName.Atlassian,
    description: 'Streamline operations with 75% off Jira, Confluence, and Trello for better work management',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Atlassian.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.atlassian.com/teams/nonprofits?utm_source=percent&utm_medium=comarketing&utm_campaign=P:multiproduct%7CO:foundation%7CF:discovery%7CW:nonprofit%7CI:percent-marketplace%7C',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  auth0: {
    type: ProductOfferType.PartnerProduct,
    name: 'Auth0',
    tracking: ProgramName.Auth0,
    description: 'Protect critical data with 50% off Auth0’s advanced security tools',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Auth0.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://auth0.com/percent-validation-form',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  box: {
    type: ProductOfferType.PartnerProduct,
    name: 'Box',
    tracking: ProgramName.Box,
    description: 'Access scalable storage solutions with 30% or more off Box’s cloud storage platform',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Box.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/ckpd-pvy-jzz',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  canva: {
    type: ProductOfferType.PartnerProduct,
    name: 'Canva',
    tracking: ProgramName.Canva,
    description: 'Design high-impact marketing materials with Canva, 100% free',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Canva.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://bit.ly/percent-canva',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  constantContact: {
    type: ProductOfferType.PartnerProduct,
    name: 'Constant Contact',
    tracking: ProgramName.ConstantContact,
    description:
      'Get 10% off monthly payments, 25% off for a 6-month prepayment, and 35% off for a year prepayment subscription to Constant Contact’s marketing automation tools.',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/ConstantContact.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://go.constantcontact.com/signup.jsp?pn=goodstack',
      linkText: 'productOffers.startFreeTrial'
    },
    secondaryButton: undefined
  },
  docusign: {
    type: ProductOfferType.PartnerProduct,
    name: 'Docusign',
    tracking: ProgramName.Docusign,
    description: 'Speed up approvals with 30% or more off DocuSign’s electronic signature solution',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Docusign.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cpkt-sjn-vnb',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  eventbrite: {
    type: ProductOfferType.PartnerProduct,
    name: 'Eventbrite',
    tracking: ProgramName.Eventbrite,
    description: "Elevate event management with 50% off Eventbrite's premium ticketing and marketing tools",
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Eventbrite.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.eventbrite.com/l/npo/',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  fiverr: {
    type: ProductOfferType.PartnerProduct,
    name: 'Fiverr',
    tracking: ProgramName.Fiverr,
    description:
      'Get $50 in Fiverr credits to start & earn more credits as you spend. Connect with skilled freelancers today.',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Fiverr.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.goodstack.org/fiverr',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  givebutter: {
    type: ProductOfferType.PartnerProduct,
    name: 'Givebutter',
    tracking: ProgramName.Givebutter,
    description: 'Streamline fundraising and donor management with Givebutter’s free all-in-one fundraising platform',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Givebutter.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://givebutter.com/?&utm_source=percent&utm_medium=referral&utm_campaign=partner_referrals',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  google: {
    type: ProductOfferType.PartnerProduct,
    name: 'Google',
    tracking: ProgramName.Google,
    description: 'Access Google for Nonprofits, for 70%+ off Workspace tools, and raise awareness through Ad Grants',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://bit.ly/percent-google',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  hootsuite: {
    type: ProductOfferType.PartnerProduct,
    name: 'Hootsuite',
    tracking: ProgramName.Hootsuite,
    description: 'Save 75% on Hootsuite to simplify and optimize your social media management',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Hootsuite.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cmcr-s75-twd',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  linkedInFundraise: {
    type: ProductOfferType.PartnerProduct,
    name: 'LinkedIn Fundraise',
    tracking: ProgramName.LinkedInFundraise,
    description: 'Connect with new donors using 75% off LinkedIn Sales Navigator Core',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Linkedin.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.goodstack.org/linkedin',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  linkedInHire: {
    type: ProductOfferType.PartnerProduct,
    name: 'LinkedIn Hire',
    tracking: ProgramName.LinkedInHire,
    description: 'Attract top talent with 75% off LinkedIn Recruiter Lite',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Linkedin.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.goodstack.org/linkedin',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  mondayCom: {
    type: ProductOfferType.PartnerProduct,
    name: 'monday.com',
    tracking: ProgramName.MondayCom,
    description: 'Manage projects with 10 free users and 70% off additional seats on monday.com',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/MondayCom.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://monday.com/nonprofits?utm_source=partner&utm_campaign=Goodstack&utm_medium=Marketplace_011024',
      linkText: 'productOffers.getStarted'
    },
    secondaryButton: undefined
  },
  newRelic: {
    type: ProductOfferType.PartnerProduct,
    name: 'New Relic',
    tracking: ProgramName.NewRelic,
    description: 'Monitor performance with 1,000 GB data and three free full-platform users on New Relic',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/NewRelic.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://newrelic.com/social-impact/signup',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  nordlayer: {
    type: ProductOfferType.PartnerProduct,
    name: 'NordLayer',
    tracking: ProgramName.NordLayer,
    description: 'Strengthen network security with 60% off NordLayer’s solutions',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Nordlayer.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cmcr-sw8-6hr',
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: undefined
  },
  okta: {
    type: ProductOfferType.PartnerProduct,
    name: 'Okta',
    tracking: ProgramName.Okta,
    description: 'Add a secure layer to your systems with 50% off Okta',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Okta.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://bit.ly/percent-okta',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  openAi: {
    type: ProductOfferType.PartnerProduct,
    name: 'OpenAI',
    tracking: ProgramName.OpenAI,
    description: 'Enhance productivity with ChatGPT Team at $20/user/month or save 50% on ChatGPT Enterprise',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Openai.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://validate.goodstack.org/openai',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  sage: {
    type: ProductOfferType.PartnerProduct,
    name: 'Sage',
    tracking: ProgramName.Sage,
    description: 'Save 50% on Sage Intacct to streamline and simplify your accounting processes',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Sage.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=fN0yPvZBLUmho8WOsCz0-PAYGA5Uma5Dk674Gui_6yJUQk8wQktGOVBXRlNIVTg4NUJPSTlHSTcxOC4u',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  splunk: {
    type: ProductOfferType.PartnerProduct,
    name: 'Splunk',
    tracking: ProgramName.Splunk,
    description: 'Handle big data with Splunk, free to use for nonprofits',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Splunk.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.splunk.com/en_us/about-us/splunk-pledge/nonprofit-license-application.html',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  },
  twilio: {
    type: ProductOfferType.PartnerProduct,
    name: 'Twilio',
    tracking: ProgramName.Twilio,
    description: 'Streamline communications with $100 credits and discounts on Twilio’s tools',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Twilio.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://www.twilio.org/support-and-resources/impact-access-program',
      linkText: 'productOffers.apply'
    },
    secondaryButton: undefined
  }
} as const

type ProductOffersKeys = keyof typeof productOffersCatalog

export const productOffers = productOffersCatalog as Record<ProductOffersKeys, ProductOffer>
