import {
  AcknowledgeModal,
  FormField,
  Modal,
  Select,
  TextInput,
  AcknowledgeModalProps,
  ConfirmationModal
} from '@percent/lemonade'
import { FormikProvider, useFormik } from 'formik'
import styles from './InviteUserModal.module.scss'
import { object, string } from 'yup'
import { useEffect, useState } from 'react'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useAuthState, useMutation, useQuery } from '@percent/cause-dashboard/common/hooks'
import { Trans, useTranslation } from 'react-i18next'
import { emailRegex } from '@percent/utility'
import { HELP_LINK } from '@percent/cause-dashboard/constants/support'

interface InviteUserModalProps {
  open: boolean
  onClose: (refresh: boolean) => void
  email?: string
}

enum InviteModalState {
  FORM,
  STATUS
}

export const InviteUserModal = ({ open, onClose, email }: InviteUserModalProps) => {
  const { t } = useTranslation()
  const { iamService } = useServices()
  const [modalState, setModalState] = useState(InviteModalState.FORM)
  const [acknowledgeModalData, setAcknowledgeModalData] = useState<AcknowledgeModalProps>({
    title: '',
    description: '',
    result: 'positive'
  })
  const [inviteeEmail, setInviteeEmail] = useState(email)

  const {
    authState: { organisation }
  } = useAuthState()
  const [{ data }] = useQuery(iamService.getRoles, {})
  const [{ isLoading: isMutating }, { apiFunc: sendInviteAPIRequest }] = useMutation(
    iamService.inviteUser,
    () => {
      setAcknowledgeModalData({
        title: t('typography.userManagement.inviteSuccessModal.title'),
        description: t('typography.userManagement.inviteSuccessModal.description', { email: inviteeEmail }),
        result: 'positive'
      })
      setModalState(InviteModalState.STATUS)
    },
    () => {
      setAcknowledgeModalData({
        title: t('typography.userManagement.inviteFailureModal.title'),
        description: (
          <Trans i18nKey="typography.userManagement.inviteFailureModal.description">
            <a href={HELP_LINK} target="_blank" rel="noreferrer" className={styles.helpLink}>
              Help Center
            </a>
          </Trans>
        ),
        result: 'negative'
      })
      setModalState(InviteModalState.STATUS)
    }
  )

  const rolesData = data?.data?.data?.roles

  const roles =
    rolesData?.map(role => {
      return { label: role.displayName, value: role.id, description: role.description }
    }) || []

  const formik = useFormik({
    initialValues: {
      email: '',
      roleId: ''
    },
    onSubmit: values => {
      sendInviteAPIRequest({
        organisationId: organisation?.id ?? '',
        email: values.email,
        roleId: values.roleId
      })
    },
    validationSchema: () =>
      object().shape({
        email: string()
          .required(t('errorMessage.required'))
          .matches(emailRegex, t('typography.userManagement.invalidEmail')),
        roleId: string()
          .required(t('errorMessage.required'))
          .oneOf(
            roles.map(role => role.value),
            t('typography.userManagement.invalidRole')
          )
      })
  })

  const { touched, errors, handleChange, handleSubmit, handleBlur, values, setFieldValue, resetForm } = formik

  useEffect(() => {
    if (email) {
      setFieldValue('email', email)
    }
  }, [email, setFieldValue])

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      {modalState === InviteModalState.FORM && (
        <ConfirmationModal
          title={t('typography.userManagement.inviteMember')}
          loading={isMutating}
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          primaryButtonText={t('button.sendInvite')}
          primaryBtnTestId="send-invite-btn"
          secondaryButtonText={t('button.cancel')}
          secondaryBtnTestId="cancel-btn"
          handleClose={() => onClose(false)}
          handleSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              {t('typography.userManagement.inviteDescription')}
              <div className={styles.inviteUserModalFormFields}>
                <FormField
                  label={t('form.email')}
                  status={touched.email && errors.email ? 'danger' : 'default'}
                  statusMessage={errors.email}
                  data-testid="email-input-field"
                >
                  <TextInput
                    name="email"
                    placeholder={t('form.enterEmailAddress')}
                    onBlur={handleBlur}
                    onChange={e => {
                      handleChange(e)
                      setInviteeEmail(e.target.value)
                    }}
                    data-testid={'email-input'}
                    value={values.email}
                  />
                </FormField>
                <FormField
                  label={t('typography.userManagement.role')}
                  status={touched.roleId && errors.roleId ? 'danger' : 'default'}
                  statusMessage={errors.roleId}
                  data-testid="role"
                >
                  <Select
                    name="roleId"
                    placeholder={t('typography.userManagement.selectRole')}
                    onChange={selection => {
                      setFieldValue('roleId', selection.value)
                    }}
                    data-testid={'role-select'}
                    options={roles}
                  />
                </FormField>
              </div>
            </FormikProvider>
          </form>
        </ConfirmationModal>
      )}
      {modalState === InviteModalState.STATUS && (
        <AcknowledgeModal
          title={acknowledgeModalData.title}
          handleClose={() => {
            setModalState(InviteModalState.FORM)
            onClose(acknowledgeModalData.result === 'positive')
          }}
          description={acknowledgeModalData.description}
          result={acknowledgeModalData.result as 'positive' | 'negative'}
          buttonText={t('button.close')}
          buttonTestId="invite-acknowledge-modal-button"
          viewTestId="invite-acknowledge-modal-view"
        />
      )}
    </Modal>
  )
}
