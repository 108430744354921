import { getApiDomainFromHostname } from '@percent/utility'
import { environments } from './constants/environments'

export const environment = process.env.NX_REACT_APP_ENV

const envConfig = (() => {
  const appDomainIo = getApiDomainFromHostname()
  const appDomainOrg = getApiDomainFromHostname().replace('.io', '.org')
  const obj = {
    urls: {
      api: '',
      app: '',
      terms: '',
      privacyAndPolicy: '',
      poweredByPercent: '',
      donationWidget: '',
      notificationBannerLink: process.env.NX_CAUSE_PORTAL_NOTIFICATION_BANNER_URL,
      goodstack: ''
    },
    keys: {
      ga: '',
      client_id: '',
      zendeskChatKey: process.env.NX_CAUSE_PORTAL_ZENDESK_CHAT_KEY ?? '',
      pk: '',
      googleCaptcha: process.env.NX_CAUSE_PORTAL_GOOGLE_RECAPTCHA_KEY ?? ''
    },
    ids: {
      donationLink: ''
    },
    promotionalPopup: {
      shortBreakTme: parseInt(process.env.NX_CAUSE_PORTAL_POPUP_SHORT_BREAK_TIME ?? '30'),
      longBreakTime: parseInt(process.env.NX_CAUSE_PORTAL_POPUP_LONG_BREAK_TIME ?? '20160')
    },
    strings: {
      notificationBanner: {
        textKey: process.env.NX_CAUSE_PORTAL_NOTIFICATION_BANNER_TEXT_KEY,
        buttonKey: process.env.NX_CAUSE_PORTAL_NOTIFICATION_BANNER_BUTTON_KEY
      }
    }
  }

  switch (environment) {
    case 'Test':
    case environments.LOCAL_SERVER:
      obj.urls.api = 'http://localhost:8080/'
      obj.urls.app = 'https://localhost:3000'
      obj.urls.terms = 'https://goodstack.org/legal'
      obj.urls.privacyAndPolicy = `https://${appDomainOrg}/legal/#privacy-policy`
      obj.urls.poweredByPercent = `https://${appDomainOrg}/`
      obj.urls.goodstack = 'https://goodstack.org/'
      obj.urls.donationWidget = `https://stating-scripts.${appDomainIo}/donation-widget-v1.js`
      obj.ids.donationLink = 'dl_292b2d39-abc1-4597-8025-8b3c0d62dd71'
      obj.keys.ga = ''
      obj.keys.client_id = ''
      obj.keys.pk = 'local_pk_46005c65-b00b-4759-8b1a-18887ef62433'
      obj.keys.zendeskChatKey = '8a2142cf-fa88-4c87-8863-b455edb69316'
      break
    case environments.LOCAL:
      obj.urls.api = `https://staging-api.${appDomainIo}/`
      obj.urls.app = 'http://localhost:4200'
      obj.urls.terms = 'https://goodstack.org/legal'
      obj.urls.privacyAndPolicy = `https://${appDomainOrg}/legal/#privacy-policy`
      obj.urls.poweredByPercent = `https://${appDomainOrg}/`
      obj.urls.goodstack = 'https://goodstack.org/'
      obj.urls.donationWidget = `https://staging-scripts.${appDomainIo}/donation-widget-v1.js`
      obj.ids.donationLink = 'dl_292b2d39-abc1-4597-8025-8b3c0d62dd71'
      obj.keys.ga = ''
      obj.keys.client_id = '_AI_02d28843-f9.b27ef1bf-aad1-40c4-960e-3a31ca78c0fc'
      obj.keys.pk = 'staging_pk_a4d10369-9545-4486-8f96-f4c99cfad34e'
      obj.keys.zendeskChatKey = '8a2142cf-fa88-4c87-8863-b455edb69316'
      break
    case environments.STAGING:
      obj.urls.api = `https://staging-api.${appDomainIo}/`
      obj.urls.app = `https://staging-causes.${appDomainOrg}`
      obj.urls.terms = 'https://goodstack.org/legal'
      obj.urls.privacyAndPolicy = `https://${appDomainOrg}/legal/#privacy-policy`
      obj.urls.poweredByPercent = `https://${appDomainOrg}/`
      obj.urls.goodstack = 'https://goodstack.org/'
      obj.urls.donationWidget = `https://staging-scripts.${appDomainIo}/donation-widget-v1.js`
      obj.ids.donationLink = 'dl_292b2d39-abc1-4597-8025-8b3c0d62dd71'
      obj.keys.ga = ''
      obj.keys.client_id = '_AI_02d28843-f9.b27ef1bf-aad1-40c4-960e-3a31ca78c0fc'
      obj.keys.pk = 'staging_pk_a4d10369-9545-4486-8f96-f4c99cfad34e'
      obj.keys.zendeskChatKey = '8a2142cf-fa88-4c87-8863-b455edb69316'
      break
    case environments.PREPROD:
      obj.urls.api = 'https://api.preproduction.goodstack.io/'
      obj.urls.app = `https://causes.preproduction.${appDomainOrg}`
      obj.urls.terms = 'https://goodstack.org/legal'
      obj.urls.privacyAndPolicy = `https://${appDomainOrg}/legal/#privacy-policy`
      obj.urls.poweredByPercent = `https://${appDomainOrg}/`
      obj.urls.goodstack = 'https://goodstack.org/'
      obj.urls.donationWidget = `https://scripts.preproduction.${appDomainIo}/donation-widget-v1.js`
      obj.ids.donationLink = 'dl_292b2d39-abc1-4597-8025-8b3c0d62dd71'
      obj.keys.ga = ''
      obj.keys.client_id = '_AI_02d28843-f9.b27ef1bf-aad1-40c4-960e-3a31ca78c0fc'
      obj.keys.pk = 'staging_pk_a4d10369-9545-4486-8f96-f4c99cfad34e' // TO BE UPDATED ONCE PREPROD HAS PROD DATA AND NOT STAGING DATA
      obj.keys.zendeskChatKey = '8a2142cf-fa88-4c87-8863-b455edb69316'
      break
    case environments.QA:
      obj.urls.api = `https://qa-api.${appDomainIo}/`
      obj.urls.app = `https://qa-causes.${appDomainOrg}`
      obj.urls.terms = 'https://goodstack.org/legal'
      obj.urls.privacyAndPolicy = `https://${appDomainOrg}/legal/#privacy-policy`
      obj.urls.poweredByPercent = `https://${appDomainOrg}/`
      obj.urls.goodstack = 'https://goodstack.org/'
      obj.urls.donationWidget = `https://qa-scripts.${appDomainIo}/donation-widget-v1.js`
      obj.ids.donationLink = 'dl_bcbba650-9c72-4bf7-ae2b-1f2f3f4d4077'
      obj.keys.ga = ''
      obj.keys.client_id = '_AI_02d28843-f9.b27ef1bf-aad1-40c4-960e-3a31ca78c0fc'
      obj.keys.pk = 'qa_pk_1f573fff-9ba8-4b45-886e-1bb64cf3ea26'
      obj.keys.zendeskChatKey = '8a2142cf-fa88-4c87-8863-b455edb69316'
      break
    case environments.SANDBOX:
      obj.urls.api = `https://sandbox-api.${appDomainIo}/`
      obj.urls.app = `https://sandbox-causes.${appDomainOrg}`
      obj.urls.terms = 'https://goodstack.org/legal'
      obj.urls.privacyAndPolicy = `https://${appDomainOrg}/legal/#privacy-policy`
      obj.urls.poweredByPercent = `https://${appDomainOrg}/`
      obj.urls.goodstack = 'https://goodstack.org/'
      obj.urls.donationWidget = `https://sandbox-scripts.${appDomainIo}/donation-widget-v1.js`
      obj.ids.donationLink = 'dl_e89bddfd-cba7-4059-a239-6914753c4132'
      obj.keys.ga = ''
      obj.keys.client_id = ''
      obj.keys.pk = 'sandbox_pk_b779ae50-c7f5-4043-957e-6035001a323b'
      obj.keys.zendeskChatKey = '8a2142cf-fa88-4c87-8863-b455edb69316'
      break
    case environments.PRODUCTION:
      obj.urls.api = `https://api.${appDomainIo}/`
      obj.urls.app = `https://causes.${appDomainOrg}`
      obj.urls.terms = 'https://goodstack.org/legal'
      obj.urls.privacyAndPolicy = `https://${appDomainOrg}/legal/#privacy-policy`
      obj.urls.poweredByPercent = `https://${appDomainOrg}/`
      obj.urls.goodstack = 'https://goodstack.org/'
      obj.urls.donationWidget = `https://scripts.${appDomainIo}/donation-widget-v1.js`
      obj.ids.donationLink = 'dl_cee39710-6706-41c1-8050-f61ddc0431b7'
      obj.keys.ga = ''
      obj.keys.client_id = ''
      obj.keys.pk = 'pk_44c829a4-a788-45b8-8276-91ac81af7d49'
      obj.keys.zendeskChatKey = '8a2142cf-fa88-4c87-8863-b455edb69316'
      break
    default:
      break
  }

  return obj
})()

export const config = {
  ...envConfig,
  version: '1.0.0'
}

export type AppConfig = typeof config
