import { ProductOffer, ProductOfferType } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { PERCENT_SERVICE_CALENDLY_URL_TILES } from './appStoreUrls'
import { ProgramName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

export const percentServicesCatalog = {
  googleWorkspaceSupport: {
    type: ProductOfferType.PercentService,
    name: 'Google Workspace support',
    tracking: ProgramName.GoogleWorkspace,
    description: 'Get expert help with setup and management of Google Workspace',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    }
  },
  googleAdsSupport: {
    type: ProductOfferType.PercentService,
    name: 'Google Ad Grants support',
    tracking: ProgramName.GoogleAdGrant,
    description: 'Create, manage, and optimize your Google Ad Grant with help from digital marketing specialists',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    }
  },
  managedItSupport: {
    type: ProductOfferType.PercentService,
    name: 'Managed IT support',
    tracking: ProgramName.ManagedIT,
    description: 'Access one-off or ongoing IT support for software planning and implementation',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  professionalGrantWriters: {
    type: ProductOfferType.PercentService,
    name: 'Professional Grant Writers',
    tracking: ProgramName.ProfessionalGrantWriters,
    description:
      'A full service agency offering foundation and federal grant writing, grant identification, and comprehensive grants management.',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/PGW.png'),
    primaryButton: {
      linkType: 'external',
      link: 'https://professionalgrantwriters.org/',
      linkText: 'productOffers.getStarted'
    },
    secondaryButton: undefined
  },
  socialMediaManagement: {
    type: ProductOfferType.PercentService,
    name: 'Social media management',
    tracking: ProgramName.SocialMedia,
    description:
      'Get expert social media strategic training or ongoing management to boost engagement and raise awareness',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  websiteCmsSupport: {
    type: ProductOfferType.PercentService,
    name: 'Website & CMS support',
    tracking: ProgramName.WebCMS,
    description: 'Engage your audience with expertly designed and managed websites tailored to your mission’s needs.',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  }
} as const

type PercentServicesKeys = keyof typeof percentServicesCatalog

export const percentServices = percentServicesCatalog as Record<PercentServicesKeys, ProductOffer>
